import React from 'react';
import AccordionNavlist from '../components/accordion-navlist';

export default function accordionNavlistPage() {
  return (
    <>
      <AccordionNavlist />
    </>
  );
}
